import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CollectionModel } from 'src/app/shared/models/collections/collection-model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CategoryModel, CategoryModelV2 } from 'src/app/shared/models/mazaya-plus/category-model';
import { DataModel } from 'src/app/shared/models/mazaya-plus/data-model';
import { UserModel } from 'src/app/shared/models/users/user-model';
import { MazayaEcardDetails } from 'src/app/shared/models/users/e-cart-details';
import { PaymentDetailsModel } from 'src/app/shared/models/mazaya-plus/payment-details-model';

@Injectable({
  providedIn: 'root'
})
export class MazayaPlusService {
  private collections = `${environment.serverUrl}/MazayaCategory/getallmembership_old`;
  private resource = `${environment.serverUrl}/users/`;
  private ecardcreate = `${environment.serverUrl}/MazayaEcard/ecardcreate`;
  private ecardPowercreate= `${environment.serverUrl}/MazayaPowerExplore/powerexplorecreate`;
  private paymentstatus = `${environment.serverUrl}/MazayaEcard/paymentstatus`;
  private ecardupdate = `${environment.serverUrl}/MazayaEcard/ecardupdate`;
  private getEcard = `${environment.serverUrl}/MazayaEcard/getecard`;
  private userInfo = `${environment.serverUrl}/mobile/userinfo`;
  private tabbyPayment=`https://atlas-utility.gewaninfotech.com/utility/1.0/tabby/mazaya-payment/createPayment`;
  private getAllEcard = `${environment.serverUrl}/MazayaEcard/ecardmainbyuserid`;

  constructor(private http: HttpClient) { }

  getAllMemberships(): Observable<DataModel<CategoryModel>> {
    const url = `${this.collections}`;
    return this.http.get<any>(url);
  }
  getAllMembershipsV2(isAddOn:boolean): Observable<DataModel<CategoryModelV2>> {
    const url = `${environment.serverUrl}/MazayaCategory/getalladditionalmembership?isGoldaddedon=${isAddOn}`;
    return this.http.get<any>(url);
  }

  getUserProfile(): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.resource}getuser`);
  }

  createEcard(eCartDetails: any): Observable<any> {

    return this.http.post<any>(this.ecardcreate, eCartDetails);
  }
  createPayment(tabbyDetails:any):Observable<any>{
  
    return this.http.post<any>(this.tabbyPayment, tabbyDetails);

  }
  createPower(eCartDetails: any): Observable<any> {

    return this.http.post<any>(this.ecardPowercreate, eCartDetails);
  }
  paymentStatus(eCartDetails: PaymentDetailsModel,id:string): Observable<any> {

    return this.http.post<any>(`${this.paymentstatus}?transaction_id=${id}&&status=${true}`, eCartDetails);
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  ecardUpdate(eCartDetails: FormData): Observable<any> {

    return this.http.post<any>(`${this.ecardupdate}`, eCartDetails);
  }

  getEcards(transactionId: any): Observable<any> {
    return this.http.get<any>(`${this.getEcard}?transaction_id=${transactionId}`);
  }
  getPowerExploreCards(transactionId: any): Observable<any> {
    return this.http.get<any>(`${environment.serverUrl}/mazayaPowerExplore/getPowerExploreEcard?transaction_id=${transactionId}`);
  }
  getUserInfo(): Observable<any> {
    const url = `${this.userInfo}`;
    return this.http.get<any>(url);
  }
  getEcardWithId(): Observable<any> {
    return this.http.get<any>(`${this.getAllEcard}`);
  }
  getUpgradeMembership(isGoldUpdate:boolean): Observable<any> {
    return this.http.get<any>(`${environment.serverUrl}/MazayaCategory/upgradeadditionalmembership?isGoldaddedon=${isGoldUpdate}`);
  }
  upgradeMembership(rqBody:any): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/MazayaUpgrade/ecardupgrade`,rqBody);
  }
  upgradeToGoldAddOn(rqBody:any): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/MazayaUpgrade/goldecardupgrade`,rqBody);
  }


  payment(data: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    let options = { headers: headers };
    return this.http
      .post<any>(
        'https://sbpaymentservices.payfort.com/FortAPI/paymentApi',
        data,
        options,
      )
  }


}
