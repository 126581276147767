<div class="footer-container">
    <div class="footer">
        <div class="content">
            <a [href]="url" target="_blank" style="color:#551A8B">Terms and Conditions</a>
            <span class="separator" style="margin-left: 10px; color:#551A8B">|</span>
            <a  [href]="urlFaq" target="_blank" style="margin-left: 10px;  color:#551A8B">FAQ</a>
        </div>
    </div>
    
</div>

<app-terms-modal *ngIf="modalTerms" (closeTermsModalE)="toggleModalTerms()"></app-terms-modal>
