import { PageHistoryState } from 'src/app/state';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutComponent } from './layout/layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ScrollComponent } from './layout/scroll/scroll.component';

import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './core/guards';
import { AuthState, SettingsState } from './state';
import { MainErrorHandler } from './core/handlers';
import { TokenHttpInterceptor, TokenErrorInterceptor } from './core/interceptors';
import { LoginService } from './core/services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HomeModule } from './features/home/home.module';
import { TermsModalComponent } from './layout/footer/terms-modal/terms-modal.component';
import { FormsModule } from '@angular/forms';
import { HttpErrorInterceptor } from './shared/http/errors.interceptor';
import { NgxTextOverflowClampModule } from "ngx-text-overflow-clamp";
import { UserNotificationsComponent } from './layout/navigation/user-notifications/user-notifications.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { ConfirmationGuard } from './core/guards/confirmation-guard.guard';
import { BnNgIdleService } from 'bn-ng-idle';
import { DataState } from './state/data-state';
import { CompanyRegistrationLayoutComponent } from './company-registration-layout/company-registration-layout.component';
import { AddCartComponent } from './layout/add-cart/add-cart.component';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    FooterComponent,
    ScrollComponent,
    NavigationComponent,
    TermsModalComponent,
    UserNotificationsComponent,
    LoaderComponent,
    CompanyRegistrationLayoutComponent,
    AddCartComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DeferLoadModule,
    NgxsModule.forRoot([AuthState, SettingsState, PageHistoryState, DataState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'auth.username', 'auth.email', 'auth.refresh', 'auth.roles', 'auth.refreshTime', 'auth.userId', 'pageHistory', 'data']
    }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    DeviceDetectorModule.forRoot(),
    HomeModule,
    SharedModule,
    FormsModule,
    NgxTextOverflowClampModule,
    MatRadioModule 

  ],
  providers: [
    ConfirmationGuard,
    AuthGuard,
    {
      provide: ErrorHandler,
      useClass: MainErrorHandler
    },
    BnNgIdleService,
    LoginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
