import { Component } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { CartModel } from 'src/app/shared/models/mazaya-plus/cart-model';
import { CategoryModelV2 } from 'src/app/shared/models/mazaya-plus/category-model';
import { Cart } from 'src/app/shared/models/mazaya-power/mazaya-power';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.scss']
})
export class PaymentFailureComponent {
  cartItemData: CartModel;
  cartPower: Cart = {
    items: [], totalAmount: 0, totalVat: 0, grandTotal: 0,
    PowertypeId: 0,
    ExploretypeId: 0,
    powerType: '',
    exploreType: ''
  };
  categoryList: CategoryModelV2[] = []
  constructor(
    private _router: Router,

  ) { }
  ngOnInit() {
    const storedData = localStorage.getItem('cart');
    if (storedData) {
      const data = JSON.parse(storedData);
      this.cartItemData = data
    }
    const storeedData = localStorage.getItem('v2-cart');
    if (storeedData) {
      const data = JSON.parse(storeedData);
      this.categoryList = data
    }
    const power = localStorage.getItem('cartPower');
    if (power) {
      const data = JSON.parse(power);
      this.cartPower = data
    }

  }
  getAllTypes(items: { type: string }[]): string {
   
    const types = items.map(item => item.type);
    return Array.from(new Set(types)).join(', '); 
  }
  
  gotToPaymentDetails() {
    this._router.navigate(['/home/check-out']);
  
  }
  async confirm(nextState?: RouterStateSnapshot) {

  }
  gotTosilverPaymentDetails(){
    this._router.navigate(["/mazaya-plus/check-out"]);
  }
  
}
