import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { MembershipResponse } from "src/app/shared/models/mazaya-power/mazaya-power";

import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class MazayaPowerService {
    private powerMemberships = `${environment.serverUrl}/mazayaPowerExplore/getMazayaPowerExploreCards?isPower=true `

  
    constructor(private http: HttpClient) { }

getAllMemberships(): Observable<MembershipResponse> {
    return this.http.get<MembershipResponse>(this.powerMemberships);
  }
  getPowerExploreCards(transaction_id:any): Observable<any> {
    return this.http.get<any>(`${environment.serverUrl}/mazayaPowerExplore/getPowerExploreEcard?transaction_id=${transaction_id}`);
  }
}