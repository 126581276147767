import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent {
url:string=`${window.location.origin}/terms-and-conditions`
urlFaq:string=`${window.location.origin}/faq`
  modalTerms: boolean;

  constructor( private _router: Router) { }

  toggleModalTerms(): void {
    this._router.navigate(['/terms-and-conditions']);
   // this.modalTerms = !this.modalTerms
  }
  toggleModalFaq(): void {
    this._router.navigate(['/faq']);
   // this.modalTerms = !this.modalTerms
  }


}
