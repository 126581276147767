import { Component } from '@angular/core';
import { MazayaPlusService } from 'src/app/features/mazaya-plus/services';
import { MazayaPowerService } from '../../services/power-membership.service';
import * as moment from 'moment';

@Component({
  selector: 'app-view-power-cards',
  templateUrl: './view-power-cards.component.html',
  styleUrls: ['./view-power-cards.component.scss']
})
export class ViewPowerCardsComponent {
  lastTransaction: { type: string, transactionId: string } = null
  mazayaecardmain = null
  powerDetails: any[] = []
  exploreDetails: any[] = []

  constructor(
    private _mazayaPowerService: MazayaPowerService,) {


  }
  ngOnInit() {
    this.lastTransaction = JSON.parse(localStorage.getItem("lastTransaction"))
    this.getCards();

  }
  getCards() {

    this._mazayaPowerService.getPowerExploreCards(this.lastTransaction.transactionId)
      .subscribe((res) => {
        this.mazayaecardmain = res.data.mazayaecardmain
        this.powerDetails = res.data.powerDetails
        this.exploreDetails = res.data.exploreDetails
      })
  }
  dateFormat(value: string): string {
    const date = moment(value);
    const formattedDate = date.format('DD/MM/YYYY');
    return formattedDate;
  }
}
