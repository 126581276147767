import { Component, EventEmitter, NgZone, Output, } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CartModel } from 'src/app/shared/models/mazaya-plus/cart-model';
import { Subcategory } from 'src/app/shared/models/mazaya-plus/subcategory-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Cart } from 'src/app/shared/models/mazaya-power/mazaya-power';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.scss'],
})
export class AddCartComponent {

  notificationModal: boolean = false;

  @Output() onClose = new EventEmitter();
  isV2: boolean = false;
  cartType = ""
  cartItemV2Data = []
  cartItemData: CartModel = null;
  totalAmount: number = 0;
  totalVat: number = 0;
  grandTotalAmount: number = 0;
  cartCount: Number = 0;
  cartIcon: HTMLImageElement = new Image();
  isOpen: boolean = false;
  confirmationForm: FormGroup;
  confirmDeleteChecked = false;
  cartPower: Cart = {
    items: [], totalAmount: 0, totalVat: 0, grandTotal: 0,
    PowertypeId: 0,
    ExploretypeId: 0,
    powerType: '',
    exploreType: ''
  };

  isOpen2: boolean = false;
  confirmV2: boolean = false
  confirmV2Error: string = ''

  constructor(
    private _router: Router,
    private zone: NgZone,
    private _notificationService: NotificationsService,
    private fb: FormBuilder,
    private _storageService: StorageService,
  ) {
  }

  ngOnInit() {
    this.cartIcon.src = "../../../assets/images/mazayaPlus/iconCarrier-single.svg";

    setInterval(() => {
      this.zone.run(() => {
        const localStorageValue = localStorage.getItem('cart');


        if (localStorageValue && localStorageValue !== "undefined") {

          const data = JSON.parse(localStorageValue);
          if (this.cartItemData?.subCategory && this.cartItemData.subCategory.length === 0) {

          } else if (!this.cartItemData?.subCategory) {

          }
        } else {
          this.cartCount = 0;
          this.cartItemData = null
        }

        const mazayaPlusV2Data = localStorage.getItem('v2-cart');
        if (mazayaPlusV2Data !== "undefined" && mazayaPlusV2Data) {
          this.isV2 = true;

          this.cartType = 'v2-cart'
          const data = JSON.parse(mazayaPlusV2Data);
          this.cartItemV2Data = data;
          this.cartCount = this.cartItemV2Data[0].membershipTypes.length;
          this.confirmationForm = this.fb.group({
            checkConfirm: [false, Validators.requiredTrue],
          });

          this.amountCalculationV2();
        } else {
          this.cartItemV2Data = []
        }


        const cartPowerData = localStorage.getItem('cartPower');
        if (cartPowerData !== "undefined" && cartPowerData) {
          this.cartType = 'power-cart'
          this.cartPower = JSON.parse(cartPowerData);


          if (this.cartPower?.items?.length > 0) {

            this.cartCount = this.cartPower.items.length;
          } else {
            this.cartCount = 0;
          }
        }
        else {
          this.cartPower.items = []
          this.cartPower.totalAmount = 0
          this.cartPower.totalVat = 0
          this.cartPower.grandTotal = 0
        }
      });
    }, 1000);

    this.confirmationForm = this.fb.group({
      checkConfirm: [false, Validators.requiredTrue],
    });

    this.amountCalculation();
  }


  trackByFn(index: number, item: any): any {
    return item.id; // Replace with a unique identifier for each item in your case
  }
  getCartItems() {

    const storedData = localStorage.getItem('cart');
    if (storedData && storedData !== "undefined") {
      const data = JSON.parse(storedData);
      this.cartItemData = data

      this.amountCalculation();
    }

    if (this.notificationModal == false)
      this.notificationModal = true
  }
  deleteCartItem(index: number): void {
    const hasPower = this.cartPower.items.some(item => item.type === 'Mazaya Power');
    const hasExplore = this.cartPower.items.some(item => item.type === 'Mazaya Explore');
    this.cartPower.items.splice(index, 1);



    this.cartPower.totalAmount = this.cartPower.items.reduce((sum, item) => sum + item.amount * item.count, 0);
    this.cartPower.totalVat = this.cartPower.items.reduce((sum, item) => sum + item.vat * item.count, 0);
    this.cartPower.grandTotal = this.cartPower.totalAmount + this.cartPower.totalVat;

    if (this.cartPower.items.length > 0) {
      localStorage.setItem('cartPower', JSON.stringify(this.cartPower));
    } else {
      localStorage.removeItem('cartPower');
      if (hasPower && hasExplore) {
        this._router.navigate(['/mazaya-power']);
      } else if (hasExplore) {
        this._router.navigate(['/mazaya-explore']);
      } else if (hasPower) {
        this._router.navigate(['/mazaya-power']);
      } else {
        console.warn('No valid type found for navigation');
      }
      // this._router.navigate(["/"]);
      this.notificationModal = false;
      this.onClose.emit();
    }
  }

  deleteSubCategory(i: number) {

    this.cartItemData.subCategory.splice(i, 1);
    if (this.cartItemData.subCategory.length == 0) {

      localStorage.removeItem('cart');
      this._router.navigate(["/mazaya-plus"]);
      this.notificationModal = false;
      this.onClose.emit();

    } else {
      localStorage.setItem('cart', JSON.stringify(this.cartItemData));
      this.amountCalculation();
    }

  }
  amountCalculation() {
    this.totalVat = 0;
    this.totalAmount = 0;

    for (let i = 0; i < this.cartItemData?.subCategory?.length; i++) {
      this.totalVat = this.totalVat + (this.cartItemData?.subCategory[i]?.vat) * this.cartItemData?.subCategory[i]?.count
      this.totalAmount = this.totalAmount + (this.cartItemData?.subCategory[i]?.amount) * this.cartItemData?.subCategory[i]?.count

    }
    this.grandTotalAmount = this.totalAmount + this.totalVat

  }

  onIncrement(i: number) {
    if (this.cartItemData.subCategory[i].count != this.cartItemData.subCategory[i].totalcount) {
      this.cartItemData.subCategory[i].count++
      localStorage.setItem('cart', JSON.stringify(this.cartItemData));
      this.amountCalculation()
    } else {
      this._notificationService.error(
        "",
        "Maximum count reached"
      );
    }
  }

  onDecrement(i: number) {
    if (this.cartItemData.subCategory[i].count !== 0) {
      this.cartItemData.subCategory[i].count--
      localStorage.setItem('cart', JSON.stringify(this.cartItemData));
      this.amountCalculation()
      if (this.cartItemData.subCategory[i].count == 0)
        this.deleteSubCategory(i);
    }
  }



  onCloseButton() {
    this.notificationModal = false;
    //  this.onClose.emit();
  }
  // checkout() {
  //   this.notificationModal = !this.notificationModal;
  //   this.handleLocalStorage()
  //   this._router.navigate(["/mazaya-plus/check-out"]);
  // }


  confirmCheckout() {
    if (this.confirmationForm.valid) {
      this.isOpen = false;
      this.notificationModal = !this.notificationModal;
      this.handleLocalStorage()
      this._router.navigate(["/mazaya-plus/check-out"]);
    } else {
      this.confirmationForm.markAllAsTouched();
    }
  }
  confirmCheckoutV2() {
    if (this.confirmV2) {
      this.isOpen2 = false;
      this.confirmV2Error = ''
      const hasPower = this.cartPower.items.some(item => item.type === 'Mazaya Power');
      const hasExplore = this.cartPower.items.some(item => item.type === 'Mazaya Explore');
      this.notificationModal = !this.notificationModal;
      this._router.navigate(["/mazaya-plus/check-out"]);
    }
    else{
      this.confirmV2Error = 'Please confirm to proceed'
    }
  }

  confirmCheckoutPowerV2() {
    const hasPower = this.cartPower.items.some(item => item.type === 'Mazaya Power');
    const hasExplore = this.cartPower.items.some(item => item.type === 'Mazaya Explore');
    this.notificationModal = !this.notificationModal;
    if (hasPower && hasExplore) {
      this._router.navigate(['/mazaya-power/check-out']);
    } else if (hasExplore) {
      this._router.navigate(['/mazaya-explore/check-out']);
    } else if (hasPower) {
      this._router.navigate(['/mazaya-power/check-out']);
    } else {
      console.warn('No valid type found for navigation');
    }
    // this._router.navigate(["/mazaya-plus/check-out"]);

  }

  onCancel2() {
    this.isOpen2 = false;
    this.confirmV2=false
     this.confirmV2Error = ''
  }


  openModal2() {
    // if (!this.cartItemV2Data[0].membershipTypes[0].isPurchasedMazayaPlusOld) {
    const currentDate = new Date();
    const targetDate = new Date('2025-01-01'); // January 1, 2025
    const membercardcount = localStorage.getItem('membercardcount');
    const isDateValid = currentDate >= targetDate;
    if (!isDateValid  && membercardcount!='0') {
      this.isOpen2 = true;
      this.confirmV2Error = ''
    } else {
      this.confirmV2 = true
      this.confirmCheckoutV2()

    }
  }
  onCancel() {
    this.isOpen = false;
  }
  openModal() {
    this.isOpen = true;
  }
  addBreakLine(name: string): string {
    return name.replace(/ /g, '<br/>')
  }
  deleteCart() {
    this.cartItemData = null
    localStorage.removeItem('cart');
    this.onClose.emit();
    this._router.navigate(["/mazaya-plus"]);
    this.notificationModal = false;
  }

  handleLocalStorage() {
    localStorage.setItem('cart', null);
    localStorage.setItem('cart', JSON.stringify(this.cartItemData));
  }
  updateCart() {
    const storedData = localStorage.getItem('cart');
    if (storedData && storedData !== "undefined") {
      const data = JSON.parse(storedData);
      this.cartItemData = data

      this.amountCalculation();
    } else {

      this.cartItemData = null
    }
  }
  roundAmount(amount: number): number {
    return parseFloat(amount.toFixed(2))
  }
  onIncrementV2(i: number) {

    if (this.cartItemV2Data[0].membershipTypes[i].selectedCount != this.cartItemV2Data[0].membershipTypes[i].count) {
      this.cartItemV2Data[0].membershipTypes[i].selectedCount++
      this._storageService.setMazayaMembershipV2(this.cartItemV2Data)
      this.amountCalculationV2()
    } else {
      this._notificationService.error(
        "",
        "Maximum count reached"
      );
    }
  }
  onDecrementV2(i: number) {
    if (this.cartItemV2Data[0].membershipTypes[i].selectedCount !== 0) {
      this.cartItemV2Data[0].membershipTypes[i].selectedCount--
      this._storageService.setMazayaMembershipV2(this.cartItemV2Data)
      if (this.cartItemV2Data[0].membershipTypes[i].selectedCount == 0)
        this.deleteMembershipV2(i);
      this.amountCalculationV2()
    }
  }
  deleteMembershipV2(i: number) {
    this.cartItemV2Data[0].membershipTypes.splice(i, 1);
    if (this.cartItemV2Data[0].membershipTypes.length == 0) {
      localStorage.removeItem('v2-cart');
      this.notificationModal = false;
      this.onClose.emit();

    } else {
      this._storageService.setMazayaMembershipV2(this.cartItemV2Data)
      this.amountCalculationV2();
    }

  }

  amountCalculationV2() {
    this.totalVat = 0;
    this.totalAmount = 0;

    for (let i = 0; i < this.cartItemV2Data[0].membershipTypes.length; i++) {
      this.totalVat = this.totalVat + (this.cartItemV2Data[0].membershipTypes[i].vat) * this.cartItemV2Data[0].membershipTypes[i].selectedCount
      this.totalAmount = this.totalAmount + (this.cartItemV2Data[0].membershipTypes[i].amount) * this.cartItemV2Data[0].membershipTypes[i].selectedCount

    }
    this.grandTotalAmount = this.totalAmount + this.totalVat
  }
  deleteCartV2() {
    localStorage.removeItem('v2-cart');
    this.onClose.emit();
  }
  // amountCalculationV2() {
  //   this.totalVat = 0;
  //   this.totalAmount = 0;

  //   // Check if cartItemV2Data and its membershipTypes exist
  //   if (this.cartItemV2Data && this.cartItemV2Data[0]?.membershipTypes?.length) {
  //     for (let i = 0; i < this.cartItemV2Data[0].membershipTypes.length; i++) {
  //       const item = this.cartItemV2Data[0].membershipTypes[i];
  //       this.totalVat += item.vat * item.selectedCount;  // Adding VAT per item
  //       this.totalAmount += item.amount * item.selectedCount;  // Adding amount per item
  //     }
  //     this.grandTotalAmount = this.totalAmount + this.totalVat;
  //   }
  // }

}
