import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/state';
import { Navigate } from '@ngxs/router-plugin';
import { LocalStorage } from '../security/local-storage';
import { Auth } from '../security/auth';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    if (!isAuthenticated) {
      LocalStorage.setItem(Auth.REDIRECT_URL, window.location.pathname);
    }
    if (!isAuthenticated) {
      this.store.dispatch(new Navigate(['/login']));
    } else {
      if (this.canUserGoToRoute(route)) {
        return true;
      } else {
        this.store.dispatch(new Navigate(['/']));
        return false;
      }
    }
  }

  canUserGoToRoute(route: ActivatedRouteSnapshot): boolean {
    const isMazayaPlus = localStorage.getItem('isMazayaEligible');
    const membercardcount = localStorage.getItem('membercardcount');
    const powermembercardcount = localStorage.getItem('powermembercardcount');
    const exploremembercardcount = localStorage.getItem('exploremembercardcount');
    if((route.routeConfig.path=="mazaya-plus" || route.routeConfig.path=="my-mazaya-plus") && isMazayaPlus==="true"){
      return true;
    }
    if((route.routeConfig.path=="my-mazaya-plus") && membercardcount!="membercardcount" )
    {
      return true;
    }
    if((route.routeConfig.path=="mazaya-power" || route.routeConfig.path=="my-mazaya-power") && isMazayaPlus==="true"){
      return true;
    }
    if((route.routeConfig.path=="my-mazaya-power") && powermembercardcount!="powermembercardcount" )
    {
      return true;
    }
    if((route.routeConfig.path=="mazaya-explore" || route.routeConfig.path=="my-mazaya-explore") && isMazayaPlus==="true"){
      return true;
    }
    if((route.routeConfig.path=="my-mazaya-explore") && exploremembercardcount!="exploremembercardcount" )
    {
      return true;
    }
    
    if (!route.data.expectedRoles) {
      return true;
    }
    const userRoles = this.store.selectSnapshot(AuthState.roles);
    return route.data.expectedRoles.some(role => userRoles.includes(role));
  }
}
