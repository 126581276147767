<html>
<div class="mazaya-cart">
  <div class="mazaya-cart-btn" (click)="getCartItems()" (clickOutside)="updateCart()">
    <div class="counter" *ngIf="cartCount>0">{{cartCount}}</div>
  </div>

  <div class="mazaya-cart" *ngIf="notificationModal">
    <div class="sidebar">
      <div class="check-header-card">
        <h4>My Cart</h4>
        <img (click)="notificationModal = false" class="arrow-card" src="../../../assets/images/icons/close-icon.svg"
          alt="" srcset="">
      </div>

      <div class="container-cart" *ngIf="cartItemData!=null || cartItemV2Data.length!=0">
        <div class="top-cart" *ngIf="cartType!='v2-cart' && cartType!='power-cart'">

          <div class="flex-container" *ngFor="let cartItem of cartItemData?.subCategory; let i=index; trackBy: trackByFn">
            <div class="column column-2">
              <img class="side-user" src="../../../assets/images/mazayaPlus/iconCarrier-single.svg" alt="user-sidebar">
            </div>
            <div class="column column-3">
              <div class="column-3-content">
                <div class="validity">
                  Valid Until 31st Dec 2024
                </div>
                <div class="name">{{cartItem.cardName}}</div>
                <div class="description">
                  {{cartItem.description}}
                </div>
              </div>

            </div>
            <div class="column column-2">
              <div class="price-sidebar1">
                {{cartItem.currency}}<sub class="suber-text1">{{cartItem.amount}}</sub>
              </div>
              <div class="spinner-content"
                *ngIf="cartItem.cardName!='FAMILY MEMBERSHIP' && cartItem.cardName!='SINGLE MEMBERSHIP'">
                <div class="img-user"><img src="../../../assets/images/mazayaPlus/user-filled.svg" alt="user"></div>
                <div class="img-spinner" (click)="onDecrement(i)" style="cursor: pointer;"><img
                    src="../../../assets/images/mazayaPlus/minus.svg" alt="minu"></div>
                <div>{{cartItem.count}}</div>
                <div class="img-spinner-add" (click)="onIncrement(i)" style="cursor: pointer;"><img
                    src="../../../assets/images/mazayaPlus/add.svg" alt="plus"></div>
                <div class="delete-section" (click)="deleteSubCategory(i)" style="cursor: pointer;">
                  <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
                </div>
              </div>
              <div class="delete-section"
                *ngIf="cartItem.cardName=='FAMILY MEMBERSHIP' ||  cartItem.cardName=='SINGLE MEMBERSHIP'"
                style="cursor: pointer;" (click)="deleteCart()">
                <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
              </div>
            </div>
          </div>




        </div>
        <div *ngIf="cartType==='v2-cart'">
          <div class="flex-container" *ngFor="let cartItem of cartItemV2Data[0].membershipTypes; let i=index; trackBy: trackByFn">
            <div class="column column-2">
              <img class="side-user" src="../../../assets/images/mazayaPlus/iconCarrier-single.svg" alt="user-sidebar">
            </div>
            <div class="column column-3">
              <div class="column-3-content">
                <div class="validity">
                  {{cartItem.subscriptionDuration}}
                </div>
                <div class="name">{{cartItem.cardName}}</div>
                <div class="description">
                  {{cartItem.description}}
                </div>
              </div>

            </div>
            <div class="column column-2">
              <div class="price-sidebar1">
                {{cartItem.currency}}<sub class="suber-text1">{{cartItem.amount}}</sub>
              </div>
              <div class="spinner-content"
                *ngIf="cartItem.cardName!='FAMILY MEMBERSHIP' && cartItem.cardName!='SINGLE MEMBERSHIP'">
                <div class="img-user"><img src="../../../assets/images/mazayaPlus/user-filled.svg" alt="user"></div>
                <div class="img-spinner" (click)="onDecrementV2(i)" style="cursor: pointer;"><img
                    src="../../../assets/images/mazayaPlus/minus.svg" alt="minu"></div>
                <div>{{cartItem.selectedCount}}</div>
                <div class="img-spinner-add" (click)="onIncrementV2(i)" style="cursor: pointer;"><img
                    src="../../../assets/images/mazayaPlus/add.svg" alt="plus"></div>
                <div class="delete-section" (click)="deleteMembershipV2(i)" style="cursor: pointer;">
                  <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
                </div>
              </div>
              <div class="delete-section"
                *ngIf="cartItem.cardName=='FAMILY MEMBERSHIP' ||  cartItem.cardName=='SINGLE MEMBERSHIP'"
                style="cursor: pointer;" (click)="deleteCartV2()">
                <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
              </div>
            </div>
          </div>

        </div>

        <div class="bottom-cart" *ngIf="cartType!='v2-cart' && cartType!='power-cart'">
          <div class="payment-sidebar">
            <div class="payment-text">
              <div>Total</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalAmount)}}</div>
            </div>
            <div class="payment-text">
              <div>Vat</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalVat)}}</div>
            </div>
            <div class="divider-checkout"></div>
            <div class="payment-total">
              <div>Grand Total</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(grandTotalAmount)}}</div>
            </div>

          </div>
          <button class="price-btn" (click)="openModal()">Checkout</button>
        </div>

        <div class="bottom-cart" *ngIf="cartType==='v2-cart'">
          <div class="payment-sidebar">
            <div class="payment-text">
              <div>Total</div>
              <div>{{cartItemV2Data[0].membershipTypes[0].currency}} {{roundAmount(totalAmount)}}</div>
            </div>
            <div class="payment-text">
              <div>Vat</div>
              <div>{{cartItemV2Data[0].membershipTypes[0].currency}} {{roundAmount(totalVat)}}</div>
            </div>
            <div class="divider-checkout"></div>
            <div class="payment-total">
              <div>Grand Total</div>
              <div>{{cartItemV2Data[0].membershipTypes[0].currency}} {{roundAmount(grandTotalAmount)}}</div>
            </div>

          </div>
          <button class="price-btn" (click)="openModal2()">Checkout</button>
        </div>

        <!-- <div class="bottom-cart" *ngIf="cartItemData.subCategory.length>0">
          <div class="payment-sidebar">
            <div class="payment-text">
              <div>Total</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalAmount)}}</div>
            </div>
            <div class="payment-text">
              <div>Vat</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(totalVat)}}</div>
            </div>
            <div class="divider-checkout"></div>
            <div class="payment-total">
              <div>Grand Total</div>
              <div>{{cartItemData.subCategory[0].currency}} {{roundAmount(grandTotalAmount)}}</div>
            </div>

          </div>
          <button class="price-btn" (click)="openModal()">Checkout</button>
        </div> -->
      </div>
      <div class="container-cart" *ngIf="cartItemData==null && cartItemV2Data.length==0 && cartPower?.items.length==0">
        <div class="empty-container">
          <div>
            <img src="../../../assets/images/mazayaPlus/empty.svg" alt="">
            <div>
              <div class="empty-bold">Your cart is feeling a bit light</div>
            </div>
          </div>


        </div>
      </div>
      <div class="top-cart">
        <div class="sidebar-card" *ngFor="let item of cartPower.items; let i = index; trackBy: trackByFn">
          <div class="side-overlay">
            <img class="side-user" src="../../../assets/images/mazayaPlus/single-person.svg" alt="user-sidebar">
          </div>
          <div class="card-content">
            <div class="price-validity">{{item. subscriptionDuration}}</div>
            <div class="check-header">
              <div class="title">{{ item.cardName }}</div>
              <div class="price-sidebar">
                {{ item.currency }}<sub class="suber-text">{{ item.amount }}</sub>
              </div>
            </div>
            <div class="check-header">
              <div class="para-sidebar">
                {{item.description}}
              </div>
              <div class="spinner-section-second">
                <div class="delete-section" (click)="deleteCartItem(i)" style="cursor: pointer;">
                  <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #emptyCart>
        <div class="empty-cart">Your cart is empty</div>
      </ng-template>

      <div class="bottom-cart" *ngIf="cartType==='power-cart' && cartPower.totalAmount!=0">
        <div class="payment-sidebar">
          <div class="payment-text">
            <div>Total</div>
            <div>{{ cartPower.items[0]?.currency  }} {{ roundAmount(cartPower.totalAmount) }}</div>
          </div>
          <div class="payment-text">
            <div>Vat</div>
            <div>{{ cartPower.items[0]?.currency  }} {{ roundAmount(cartPower.totalVat) }}</div>
          </div>
          <div class="divider-checkout"></div>
          <div class="payment-total">
            <div>Grand Total</div>
            <div>{{ cartPower.items[0]?.currency  }} {{ roundAmount(cartPower.grandTotal) }}</div>
          </div>
        </div>
        <button class="price-btn" [disabled]="cartPower.items.length === 0" (click)="confirmCheckoutPowerV2()">Checkout</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal-container" *ngIf="isOpen">
  <div class="modal modal-width-m">
    <div class="modal-header">

      <h2>Confirmation</h2>
      <p class="warning-text">
        <b> Membership is valid until 31st Dec 2024 </b>

      </p>
    </div>
    <div class="modal-body">

      <form [formGroup]="confirmationForm">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="confirmCheck" formControlName="checkConfirm">
          <label class="form-check-label" for="confirmCheck">
            I understand and agree
          </label>
        </div>
        <div class="error"
          *ngIf="confirmationForm.get('checkConfirm').invalid && confirmationForm.get('checkConfirm').touched">
          Please confirm to proceed
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn-confirm" (click)="confirmCheckout()">Proceed</button>
      <button type="button" class="btn-cancel" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</div>

<div class="modal-container" *ngIf="isOpen2">
  <div class="modal modal-width-m">
    <div class="modal-header">

      <h2>Confirmation</h2>
      <p class="warning-text">
        <b> Your new membership will be activated on 1st January 2025 as you already have an existing membership which is valid until 31st December 2024 </b> 
        

      </p>
    </div>
    <div class="modal-body">

     
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="confirmCheck" [(ngModel)]="confirmV2">
          <label class="form-check-label" for="confirmCheck">
            I understand and agree
          </label>
        </div>
        <div class="error"
        >
          {{confirmV2Error}}
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn-confirm" (click)="confirmCheckoutV2()">Proceed</button>
      <button type="button" class="btn-cancel" (click)="onCancel2()">Cancel</button>
    </div>
  </div>
</div>

</html>