import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  Actions,
  ofActionDispatched,
  Store,
  ofActionSuccessful,
} from "@ngxs/store";
import { ActivatedRoute, ActivationStart, Router } from "@angular/router";
import {
  Logout,
  Login,
  CheckIsImageDeferLoadAllowed,
  AuthState,
  SaveIdentityProvidersConfiguration,
  LoginWithToken,
  LogInfo,
  UpdateCategories,
  UpdateCollections,
  UpdateTags,
  UpdateLocations,
  UpdateAreas,
  UpdateMemberships,
} from "./state";
import { Navigate, RouterDataResolved } from "@ngxs/router-plugin";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Meta, Title } from "@angular/platform-browser";
import { BnNgIdleService } from "bn-ng-idle";
import jwt_decode from "jwt-decode";
import { IdentityProviersService } from "./core/services/identity-providers.service";
import { UserNotificationsService } from "./shared/services/user-notification.service";
import { FiltersService } from "./shared/services/filters.service";
import { decode } from "querystring";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private subscriptions = new Subscription();
  private _token: string;

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private _titleService: Title,
    private _userNotificationService: UserNotificationsService,
    // private _signalRService: SignalRService,
    private _bnNgIdleService: BnNgIdleService,
    private identityProvidersService: IdentityProviersService,
    private $meta: Meta,
    private _filterService: FiltersService,
    _route: ActivatedRoute
  ) {
    // this._bnNgIdleService.startWatching(1800).subscribe((res) => {
    //   if (res) {
    //     debugger
    //     this.store.dispatch(new Logout());
    //     console.log("session expired");
    //   }
    // });

    this._token = this.store.selectSnapshot(AuthState.token);
    if (this._token) {
      let decoded = jwt_decode(this._token);
      if (this.checkIfTokenExpired(decoded.exp))
        this.store.dispatch(new Logout());
    }
  }

  async ngOnInit() {
    const hash = window.location.hash;
    await this.handleAuthenticationResult(hash);
    this.changeTitle();

    this.subscriptions.add(
      this.actions$.pipe(ofActionDispatched(Login)).subscribe(() => { })
    );

    this.subscriptions.add(
      this.actions$
        .pipe(ofActionSuccessful(RouterDataResolved), takeUntil(this.destroy$))
        .subscribe((action: RouterDataResolved) => { })
    );

    this.store.dispatch(new CheckIsImageDeferLoadAllowed());

    this.store.select(AuthState.token).subscribe((token) => {
      if (token) {

        let decoded = jwt_decode(token);
        if (decoded.companyStatus == "MissingTradeLicense") {
          this.store.dispatch(new Navigate(['registration/company-trade-license-update']));
        } else
          if (decoded.roles == undefined || decoded.roles.length == 0 || decoded.roles == null) {
            this.store.dispatch(new LogInfo());
          } else {
            // this._signalRService.connect();
            this._userNotificationService.getNotificationsCount();
            this.loadBasicData();
            this.initMeta();
            this.initUserInfo();
          }
      } else {
        this.fetchIdentityProviders();
        // this._signalRService.disconnect();
      }
    });
  }

  private async handleAuthenticationResult(hash: string) {
    const results = await this.identityProvidersService.getAuthenticationResults(
      hash
    );
    results.forEach(({ tokenData }) =>
      this.store.dispatch(new LoginWithToken(tokenData))
    );
    this.identityProvidersService.clearIdentityStorage();
  }

  private fetchIdentityProviders() {
    this.identityProvidersService
      .getIdentityProviders()
      .toPromise()
      .then((config) =>
        this.store.dispatch(new SaveIdentityProvidersConfiguration(config))
      );
  }

  loadBasicData() {
    this._filterService.getFilterData()
      .subscribe((res) => {
        const memberships = res.memberships.map(x => ({
          id: x.id,
          title: x.nameEng,
          nameEng: x.nameEng
        }));

        this.store.dispatch(new UpdateCategories(res.category));
        this.store.dispatch(new UpdateCollections(res.collections));
        this.store.dispatch(new UpdateTags(res.tags));
        this.store.dispatch(new UpdateLocations(res.locations));
        this.store.dispatch(new UpdateAreas(res.areas));
        this.store.dispatch(new UpdateMemberships(memberships));
      });
  }
  initUserInfo() {
    this._filterService.getUserInfo().subscribe((res) => {
   
      localStorage.setItem('isMazayaEligible', res.isMazayaEligible)
      localStorage.setItem('isMazayaAdmin', res.mazayauserdetails.isAdmin)
      localStorage.setItem('membercardcount', res.mazayauserdetails.membercardcount)
      localStorage.setItem('powermembercardcount', res.powerexploredetails.powermembercardcount)
      localStorage.setItem('exploremembercardcount', res.powerexploredetails.exploremembercardcount)
      console.log
    })
  }

  changeTitle() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        let data = event.snapshot.data;
        if (data["title"]) {
          this._titleService.setTitle("Mazaya - " + data["title"]);
        } else {
          this._titleService.setTitle("Mazaya");
        }
      }
    });
  }

  checkIfTokenExpired(exp: number) {
    let tokenDate = new Date(0);
    tokenDate.setUTCSeconds(exp);

    return new Date() > tokenDate;
  }

  initMeta() {
    this.$meta.addTag({ name: 'og:image', content: `${window.location.hostname}/assets/images/backgrounds/login_background.jpg` });
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
    // this._signalRService.disconnect();
  }
}
