import { Component } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { PaymentDetailsModel } from 'src/app/shared/models/mazaya-plus/payment-details-model';
import { MazayaPlusService } from '../../services';
import { CartModel } from 'src/app/shared/models/mazaya-plus/cart-model';
import { MazayaEcardData } from 'src/app/shared/models/mazaya-plus/membership-details-model';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent {
  data: MazayaEcardData;
  transactionDetails = null;
  cartItemData: CartModel;
  lastTransaction: { type: string, transactionId: string } = null
  constructor(
    private _router: Router,
    private _mazayaPlusService: MazayaPlusService
  ) {
  }

  ngOnInit() {
    localStorage.removeItem('cart');
    localStorage.removeItem('v2-cart');
    localStorage.removeItem('cartPower');
    this.lastTransaction = JSON.parse(localStorage.getItem("lastTransaction"))
    if (this.lastTransaction.type === "v1-cart") {
      this._mazayaPlusService.getEcards(this.lastTransaction.transactionId)
        .subscribe((res) => {
          localStorage.removeItem('cart');

          this.data = res.data
        })
    }
    if (this.lastTransaction.type === "v1-cart") {
      this._mazayaPlusService.getEcards(this.lastTransaction.transactionId)
        .subscribe((res) => {
          localStorage.removeItem('cart');

          this.data = res.data
        })
    }
    if (this.lastTransaction.type === "v2-cart") {
      this._mazayaPlusService.getEcards(this.lastTransaction.transactionId)
        .subscribe((res) => {


          this.data = res.data
        })
    }
    if (this.lastTransaction.type === "power-cart") {
      this._mazayaPlusService.getPowerExploreCards(this.lastTransaction.transactionId)
        .subscribe((res) => {
          this.transactionDetails = res.data

        })
    }


  }
  onViewCardClick() {
    this._router.navigate(['/mazaya-plus/view-card'], { queryParams: { jsonData: this.data.mazayaecardmain.id } });



  }
  onViewPowerCardClick() {
    this._router.navigate(['/home/view-card']);
  }
  onBackToHomeClick() {

    this._router.navigate(['/']);
  }
  async confirm(nextState?: RouterStateSnapshot) {

  }
}
