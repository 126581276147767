<div class="payment-success-container">
  <div *ngIf="data && lastTransaction.type=='v1-cart'">
    <img src="assets/images/mazayaPlus/payment-success.svg" alt="payment-success">
    <div class="text-green">Payment Success!</div>
    <div class="price-btn">{{data.mazayaecardmain?.currency}} {{data.mazayaecardmain.grandtotal}}</div>
    <p class="payment-success-para">Hooray! Your payment process has been <br> completed successfully.</p>
    <div class="success-details">
      <p>Congratulations on choosing <br><span class="text-bold">{{data.mazayaecardmain.category_name}}</span> <br> your
        ultimate gateway to exclusive offers and savings!</p>
      <div class="success-details-p">You are now a proud member of our <br> premium service.</div>
      <button (click)="onViewCardClick()">View Card</button>
    </div>
    <button class="back-btn" (click)="onBackToHomeClick()">Back to Home</button>
  </div>

  <div *ngIf="data && lastTransaction.type=='v2-cart'">
    <img src="assets/images/mazayaPlus/payment-success.svg" alt="payment-success">
    <div class="text-green">Payment Success!</div>
    <div class="price-btn">{{data.mazayaecardmain?.currency}} {{data.mazayaecardmain.grandtotal}}</div>
    <p class="payment-success-para">Hooray! Your payment process has been <br> completed successfully.</p>
    <div class="success-details">
      <p>Congratulations on choosing <br>
        <span class="text-bold" >{{data.mazayaecardmain.category_name}}</span>
         <br> your
        ultimate gateway to exclusive offers and savings!</p>
      <div class="success-details-p">You are now a proud member of our <br> premium service.</div>
      <button (click)="onViewCardClick()">View Card</button>
    </div>
    <button class="back-btn" (click)="onBackToHomeClick()">Back to Home</button>
  </div>


  <div *ngIf=" transactionDetails !=null && lastTransaction.type=='power-cart'">
    <img src="assets/images/mazayaPlus/payment-success.svg" alt="payment-success">
    <div class="text-green">Payment Success!</div>
    <div class="price-btn">
    {{transactionDetails.mazayaecardmain.currency}} {{transactionDetails.mazayaecardmain.grandtotal}}
    </div>
    <p class="payment-success-para">Hooray! Your payment process has been <br> completed successfully.</p>
    <div class="success-details">
      <p>Congratulations on choosing 
        <span class="text-bold" *ngIf="transactionDetails.exploreDetails.length>0">{{transactionDetails.exploreDetails[0]?.type}}</span>
        <span class="text-bold" *ngIf="transactionDetails.powerDetails.length>0 && transactionDetails.exploreDetails.length>0"> & </span>
        <span class="text-bold" *ngIf="transactionDetails.powerDetails.length>0">{{transactionDetails.powerDetails[0]?.type}}</span>
        <span class="text-bold">

        </span> <br> your
        ultimate gateway to exclusive offers and savings!</p>
      <div class="success-details-p">You are now a proud member of our <br> premium service.</div>
      <button (click)="onViewPowerCardClick()">View Card</button>
    </div>
    <button class="back-btn" (click)="onBackToHomeClick()">Back to Home</button>
  </div>



</div>