<div class="pagination-container" *ngIf="totalPage > 0">
    <div class="pagination">
        <div class="first" *ngIf="showComplexPagination" (click)="onLoadFirstPage()"></div>
        <div class="prev" *ngIf="showComplexPagination" (click)="onLoadPreviousPage()"></div>
        <div class="counter">
            <div class="currentPage">{{currentPage}}</div>
            of
            <div class="totalPage">{{totalPage}}</div>
        </div>
        <div class="next" *ngIf="showComplexPagination" (click)="onLoadNextPage()"></div>
        <div class="last" *ngIf="showComplexPagination" (click)="onLoadLastPage()"></div>
    </div>
</div>
