import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CollectionModel } from 'src/app/shared/models/collections/collection-model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CategoryModel } from 'src/app/shared/models/mazaya-plus/category-model';
import { DataModel } from 'src/app/shared/models/mazaya-plus/data-model';
import { UserModel } from 'src/app/shared/models/users/user-model';
import { MazayaEcardDetails } from 'src/app/shared/models/users/e-cart-details';
import { PaymentDetailsModel } from 'src/app/shared/models/mazaya-plus/payment-details-model';
import { CardDetailsModel, MemberGenderModel } from 'src/app/shared/models/mazaya-plus/card-details-model';

@Injectable({
  providedIn: 'root'
})
export class MyMazayaPlusService {
  private resouces =`${environment.serverUrl}`;
  private getEcard =`${environment.serverUrl}/MazayaEcard/ecardmembers`;
  private getAllTransactions =`${environment.serverUrl}/MazayaEcard/gettransactionlog`;
  private getAllEcard =`${environment.serverUrl}/MazayaEcard/ecardmainbyuserid`;
  private ecardupdate =`${environment.serverUrl}/MazayaEcard/ecardupdate`;
  private updateECardDetails =`${environment.serverUrl}/MazayaEcard/update-carddetails`;

  constructor(private http: HttpClient) { }
  getEcards(): Observable<any> {
    return this.http.get<any>(`${this.getEcard}?isAdmin=true`);
  }
  getEcardsRenew(): Observable<any> {
    return this.http.get<any>(`${this.getEcard}?isAdmin=true&isRenew=true`);
  }
  getEcardsWithoutAdmin(): Observable<any> {
    return this.http.get<any>(`${this.getEcard}`);
  }
  getEcardWithId(): Observable<any> {
    return this.http.get<any>(`${this.getAllEcard}`);
  }
  getTransactions(isMazayaPlus: boolean, isExplore: boolean, isPower: boolean): Observable<any> {
    const params = {
      ismazayaplus: isMazayaPlus,
      isexplore: isExplore,
      ispower: isPower
    };
    return this.http.get<any>(`${this.getAllTransactions}`, { params });
  }

  updateCardDetails(eCartDetails: CardDetailsModel): Observable<any> {
    return this.http.post<any>(`${this.updateECardDetails}`, eCartDetails);
  }
  ecardUpdate(eCartDetails: FormData): Observable<any> {
    return this.http.post<any>(`${this.ecardupdate}`, eCartDetails);
  }
  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
  cardprofileUpdate(id:number, imageid:string): Observable<any> {
    return this.http.post<any>(`${this.resouces}/MazayaEcard/modifyecard?id=${id}&imgid=${imageid}`,null);
  }
  resendInvite(emailId:string): Observable<any> {
    return this.http.post<any>(`${this.resouces}/MazayaEcard/resendinvitation?mail_id=${emailId}`,null);
  }

  getMembersWithoutGender(isAdmin:boolean): Observable<any> {
    return this.http.get<any>(`${this.resouces}/MazayaEcard/ecardmembersWithoutGenderandphone?isAdmin=${isAdmin}`);
  }
  updateGender(members:MemberGenderModel): Observable<any> {
    return this.http.post<any>(`${this.resouces}/MazayaEcard/update-gender-phone`, members);
  }
}
