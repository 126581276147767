import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages";
import { HomeSupplierAdminComponent } from "./pages/home/home-supplier-admin/home-supplier-admin.component";
import { PaymentSuccessComponent } from "../mazaya-plus/pages/payment-success/payment-success.component";
import { PaymentFailureComponent } from "../mazaya-plus/pages/membership/payment-failure/payment-failure.component";
import { ViewPowerCardsComponent } from "../mazaya-power/pages/view-power-cards/view-power-cards.component";
import { PaymentDetailsComponent } from "../mazaya-plus/pages/payment-details/payment-details.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "supplier-admin",
    component: HomeSupplierAdminComponent,
  },
  {
    path: "payment-success",
    component: PaymentSuccessComponent,
  },
  {
    path: "payment-failure",
    component: PaymentFailureComponent,
  },
  {
    path: 'view-card',
    component: ViewPowerCardsComponent, 
  },
  {
    path: 'check-out',
    component: PaymentDetailsComponent, 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule { }
