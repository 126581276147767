import { Injectable } from "@angular/core";
import { QueryModel } from "../models/query/queryModel";
import { order } from "../models/query/sortModel";
import { CategoryModelV2 } from "../models/mazaya-plus/category-model";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() { }

  getQuery(sort: string, url: string) {
    if (sessionStorage.url !== null && sessionStorage.getItem("url") === url) {
      return JSON.parse(sessionStorage.query);
    } else {
      let query = new QueryModel();
      query.sort = {
        type: sort,
        order: order.DESC,
      };
      return query;
    }
  }

  getFilterActive(url: string) {
    if (sessionStorage.url !== null && sessionStorage.getItem("url") === url) {
      return JSON.parse(sessionStorage.filterActive)
    }
  }

  setQuery(query: QueryModel, url: string) {
    sessionStorage.setItem("query", JSON.stringify(query));
    sessionStorage.setItem("url", url);
  }

  setFilterActive(filterActive: boolean) {
    sessionStorage.setItem("filterActive", JSON.stringify(filterActive));
  }
  // Silver & Gold
  setMazayaMembershipV2(data: CategoryModelV2[]) {

    localStorage.setItem("v2-cart", JSON.stringify(data));
  }
  getMazayaMembershipV2() {

    localStorage.getItem("v2-cart");
  }
  clearMazayaMembershipV2() {

    sessionStorage.removeItem("v2-cart");
  }

}
