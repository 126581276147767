<div class="e-card-container">
    <div class="title" *ngIf="exploreDetails.length>0">My Explore Cards</div>
    <div id="exploreDetails" *ngFor="let card of exploreDetails; let i = index">
        <div class="carousel-card">
            <div class="card-container">
                <div class="card">
                    <div class="card-front">
                        <img src="assets/images/mazayaPlus/Mazaya-Cards/grey-blur.svg" alt="front">
                        <div class="avatar-bottom-left">

                            <!-- <img *ngIf=".profile_image"
                            [src]=".profile_image" alt=""> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="second-text">{{card.sub_type}} <span class="view-benefits"><a [href]="card.subBenefitsURL"
                    target="_blank">View Benefits</a></span></div>
        <div class="purcahse-details-section">
            <div class="purcahse-section-main">
                <div class="purcahse-section">
                    <div class="purcahse-date">Date of Purcahse</div>
                    <div class="purcahse-date-sec">{{dateFormat(card.createdOn)}}</div>
                </div>
                <div class="purcahse-section">
                    <div class="purcahse-date">Activation Date</div>
                    <div class="purcahse-date-sec">{{dateFormat(card.createdOn)}}</div>
                </div>
                <div class="purcahse-section">
                    <div class="purcahse-date">Expiring On</div>
                    <div class="purcahse-date-sec">{{dateFormat(card.card_expire)}}</div>
                </div>

            </div>
            <div class="purcahse-details">
                <div class="purchase-title">Purchase Details</div>
                <div class="purcahse-section-table">
                    <div class="purcahse-date">Invoice No</div>
                    <div class="purcahse-date-sec">{{mazayaecardmain.invoice_number}}</div>
                </div>
                <div class="purcahse-section-table">
                    <div class="purcahse-date">Total Amount</div>
                    <div class="purcahse-date-sec">{{mazayaecardmain.amount}}
                        {{mazayaecardmain.currency}}</div>
                </div>
                <div class="purcahse-section-table">
                    <div class="purcahse-date">VAT</div>
                    <div class="purcahse-date-sec">{{mazayaecardmain.vat}}
                        {{mazayaecardmain.currency}}</div>
                </div>
                <div class="purcahse-section-table">
                    <div class="purcahse-date">Amount Paid</div>
                    <div class="purcahse-date-sec">{{mazayaecardmain.grandtotal}}
                        {{mazayaecardmain.currency}}</div>
                </div>
            </div>

        </div>
        

        <!-- 
    <a [href]="membershipDetails.mazayaecardmain.pdf_url" target="_blank">
      
        <div class="download-btn">
          
                <img class="down-arrow" src="assets/images/mazayaPlus/download-icon.svg" alt="" srcset="">
                Download Invoice

        </div>
    </a> -->

    </div>
    <div class="title" *ngIf="powerDetails.length>0">MY Power Cards</div>
    <div id="powerDetails" *ngFor="let card of powerDetails; let i = index">
        <div class="carousel-card">

            <div class="card-container">
                <div class="card">
                    <div class="card-front">
                        <img src="assets/images/mazayaPlus/Mazaya-Cards/grey-blur.svg" alt="front">
                        <div class="avatar-bottom-left">

                            <!-- <img *ngIf=".profile_image"
                            [src]=".profile_image" alt=""> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="second-text">{{card.sub_type}} <span class="view-benefits"><a [href]="card.subBenefitsURL"
                    target="_blank">View Benefits</a></span></div>
        <div class="purcahse-details-section">
            <div class="purcahse-section-main">
                <div class="purcahse-section">
                    <div class="purcahse-date">Date of Purcahse</div>
                    <div class="purcahse-date-sec">{{dateFormat(card.createdOn)}}</div>
                </div>
                <div class="purcahse-section">
                    <div class="purcahse-date">Activation Date</div>
                    <div class="purcahse-date-sec">{{dateFormat(card.createdOn)}}</div>
                </div>
                <div class="purcahse-section">
                    <div class="purcahse-date">Expiring On</div>
                    <div class="purcahse-date-sec">{{dateFormat(card.card_expire)}}</div>
                </div>

            </div>
            <div class="purcahse-details">
                <div class="purchase-title">Purchase Details</div>
                <div class="purcahse-section-table">
                    <div class="purcahse-date">Invoice No</div>
                    <div class="purcahse-date-sec">{{mazayaecardmain.invoice_number}}</div>
                </div>
                <div class="purcahse-section-table">
                    <div class="purcahse-date">Total Amount</div>
                    <div class="purcahse-date-sec">{{mazayaecardmain.amount}}
                        {{mazayaecardmain.currency}}</div>
                </div>
                <div class="purcahse-section-table">
                    <div class="purcahse-date">VAT</div>
                    <div class="purcahse-date-sec">{{mazayaecardmain.vat}}
                        {{mazayaecardmain.currency}}</div>
                </div>
                <div class="purcahse-section-table">
                    <div class="purcahse-date">Amount Paid</div>
                    <div class="purcahse-date-sec">{{mazayaecardmain.grandtotal}}
                        {{mazayaecardmain.currency}}</div>
                </div>
            </div>

        </div>





    </div>

    <a [href]="mazayaecardmain?.pdf_url" target="_blank">

        <div class="download-btn">

            <img class="down-arrow" src="assets/images/mazayaPlus/download-icon.svg" alt="" srcset="">
            Download Invoice

        </div>
    </a>

</div>