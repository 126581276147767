<div class="payment-fail-container">
    <div *ngIf="cartItemData && cartItemData.membershipName">
        <img src="assets/images/mazayaPlus/payment-fail.svg" alt="payment-fail">
        <div class="text-yellow">Oops!</div>
        <p class="payment-fail-para">We regret to inform you that your payment <br> for <span class="text-bold">
            {{cartItemData.membershipName}} </span><br> has not been successful.</p>
        <div class="fail-details">
            <p>If you need immediate assistance or have any questions, <br> don't hesitate to contact our dedicated
                support team at <br> support@mazayaoffers.ae or 023046969. </p>
            <div class="fail-details-p">Our team is available to assist you during our <br> business hours.</div>
            <button routerLink="/mazaya-plus/contact-us">Contact Us</button>
        </div>
        <button class="try-btn" (click)=" gotTosilverPaymentDetails()">Try Again</button>
    </div>
    <div *ngIf="categoryList && categoryList.length > 0">
        <div *ngFor="let category of categoryList">
          <img src="assets/images/mazayaPlus/payment-fail.svg" alt="payment-fail">
          <div class="text-yellow">Oops!</div>
          <p class="payment-fail-para">
            We regret to inform you that your payment <br> for 
            <span class="text-bold">
          
              <span >
                {{ category.type }}
              
              
              </span>
            </span><br>
            has not been successful.
          </p>
          <div class="fail-details">
            <p>
              If you need immediate assistance or have any questions, <br>
              don't hesitate to contact our dedicated support team at <br>
              <a href="mailto:support@mazayaoffers.ae">support@mazayaoffers.ae</a> or <a href="tel:023046969">023046969</a>.
            </p>
            <div class="fail-details-p">
              Our team is available to assist you during our <br> business hours.
            </div>
            <button routerLink="/mazaya-plus/contact-us">Contact Us</button>
          </div>
          <button class="try-btn" (click)=" gotTosilverPaymentDetails()">Try Again</button>
        </div>
      </div>
      
    <div *ngIf="cartPower && cartPower.items.length > 0">
        <img src="assets/images/mazayaPlus/payment-fail.svg" alt="payment-fail">
        <div class="text-yellow">Oops!</div>
        <p class="payment-fail-para">
          We regret to inform you that your payment <br> for 
          <span class="text-bold">{{ getAllTypes(cartPower.items) }}</span><br>
         
          <br> 
          has not been successful.
        </p>
        <div class="fail-details">
          <p>
            If you need immediate assistance or have any questions, <br> 
            don't hesitate to contact our dedicated support team at <br> 
            <a href="mailto:support@mazayaoffers.ae">support@mazayaoffers.ae</a> or <a href="tel:023046969">023046969</a>.
          </p>
          <div class="fail-details-p">Our team is available to assist you during our <br> business hours.</div>
          <button routerLink="/mazaya-plus/contact-us">Contact Us</button>
        </div>
        <button class="try-btn" (click)="gotToPaymentDetails()">Try Again</button>
      </div>
</div>