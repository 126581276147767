import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/services';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-termsand-conditions',
  templateUrl: './termsand-conditions.component.html',
  styleUrls: ['./termsand-conditions.component.scss']
})
export class TermsandConditionsComponent {
  buyerTermsContent: SafeHtml;
  constructor(
    private router: Router,
    private _navigationService: NavigationService,
    private loginService: LoginService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit(): void {
    this.buyerTerms();
  }
goToLogin(){
  this._navigationService.back();
}
 
  buyerTerms(){
    this.loginService.getBuyerTermsAndCondition().subscribe((res: any) => {
      console.log("ddd", res.data)
      this.buyerTermsContent = res.data[0].content;
    });
  }



}
